<template>
  <div>
    <el-form
      label-width="100px"
      label-position="left"
      :model="property"
      :rules="rules"
      ref="propertyForm"
    >
      <el-form-item label="Key" prop="key">
        <el-input
          placeholder="It cannot be empty!"
          v-model="property.key"
          :disabled="disabled"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-for="item in selectedLangs"
        :key="item"
        :prop="item"
        :label="dic[item]"
      >
        <el-input
          v-model="property[item]"
          type="textarea"
          @input="changeValue"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="English" prop="en">
        <el-input v-model="property.en" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="Chinese" prop="zh">
        <el-input v-model="property.zh" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="German" prop="de">
        <el-input v-model="property.de" type="textarea"></el-input>
      </el-form-item> -->
      <el-form-item>
        <div
          style="width: 100%; height: 30px; line-height: 30px; float:left; margin-top: 20px;"
        >
          <el-button
            type="primary"
            style="float: right;"
            size="mini"
            @click="handleConfirm"
            >Confirm</el-button
          >
          <el-button
            style="float:right; margin-right: 10px;"
            size="mini"
            @click="handleCancel"
            >Cancel</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import http from "../utils/http";
import config from "../utils/config";
export default {
  name: "DicDetail",
  props: {
    editProperty: {},
  },
  data() {
    return {
      rules: {
        key: [
          {
            required: true,
            message: "Key is required.",
            trigger: "blur",
            default: "",
          },
        ],
      },
      disabled: false,
      selectedLangs: localStorage.getItem("selectedLangs").split(","),
      property: {},
      dic: {},
    };
  },
  created() {
    // console.log(this.selectedLangs);
    config.langs.forEach((lang) => {
      this.dic[lang.key] = lang.value;
    });
    // console.log(this.dic);
    if (
      this.editProperty != null &&
      JSON.stringify(this.editProperty) != "{}"
    ) {
      for (var item in this.editProperty) {
        // console.log(item);
        this.property[item] =
          this.editProperty[item] == null ? "" : this.editProperty[item];
      }
      this.disabled = true;
      // this.property._id = this.editProperty._id;
      // this.property.key = this.editProperty.key;
      // this.property.en =
      //   this.editProperty.en == null ? "" : this.editProperty.en;
      // this.property.zh =
      //   this.editProperty.zh == null ? "" : this.editProperty.zh;
      // this.property.de =
      //   this.editProperty.de == null ? "" : this.editProperty.de;
    } else {
      // this.selectedLangs.forEach((value) => {
      //   this.property[value] = "";
      // });
      this.property = {};
    }
  },
  methods: {
    changeValue() {
      this.$forceUpdate();
    },
    handleConfirm() {
      this.$refs.propertyForm.validate((valid) => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    save() {
      http.post("/property/save", this.property).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: "The property was saved successfully.",
          });
          this.$emit("confirm", this.property);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
};
</script>
<style scoped></style>
