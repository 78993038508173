var permissions = ["admin", "requester"];
var requestTypes = [
  "Service Request",
  "Bug",
  "Requirement",
  "Change",
  "Problem",
];
var customerTypes = ["prospect", "existing"];
var requestStatuses = ["New", "In Progress", "Pending", "Closed", "Reopened"];
var priorities = ["P0", "P1", "P2", "P3", "P4", "P5"];

var userTypes = ["internal", "external"];

var areas = [
  "Beijing",
  "Shanghai",
  "Ningbo",
  "Shenzhen",
  "Guangzhou",
  "Suzhou",
  "Hefei",
];

// var langs = {
//   de: "German",
//   en: "English",
//   zh: "Chinese",
//   ja: "Japanese",
//   ko: "Korea",
//   fr: "France",
//   es: "Spanish"
// }

var langs = [
  { key: "en", value: "English" },
  { key: "zh", value: "Chinese" },
  { key: "ja", value: "Japanese" },
  { key: "ko", value: "Korea" },
  { key: "de", value: "German" },
  { key: "fr", value: "France" },
  { key: "es", value: "Spanish" },
  { key: "v1", value: "Version 1" },
  { key: "v2", value: "Version 2" },
]

var pocStatuses = ["not initialized", "running", "stopped", "terminated"];

export default {
  permissions,
  requestTypes,
  requestStatuses,
  priorities,
  userTypes,
  customerTypes,
  areas,
  pocStatuses,
  langs
};
