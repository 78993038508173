<template>
  <div>
    <div class="header">
      <el-upload
        action=""
        :on-change="handleOnChange"
        :show-file-list="false"
        style="float:left;"
        accept=".properties"
      >
        <el-tooltip
          class="item"
          effect="dark"
          content="Click to upload the English properties which need to be translated."
        >
          <el-button type="primary" size="small" icon="el-icon-upload2" round
            >Upload English</el-button
          >
        </el-tooltip>
      </el-upload>
      <el-select
        v-model="selectedLangs"
        multiple
        size="small"
        collapse-tags
        style="margin-left: 20px; float: left;"
        placeholder="Choose Languages"
        @change="handleLangChange"
      >
        <el-option
          v-for="item in langs"
          :key="item.key + Math.random()"
          :label="item.value"
          :value="item.key"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="kw"
        size="small"
        style="width: 200px; margin-left: 10px; float: left;"
        placeholder="Search..."
        clearable
        suffix-icon="el-icon-search"
      ></el-input>
      <el-checkbox
        v-model="onlyShowEmpty"
        :border="true"
        size="small"
        style="float:left; margin-left: 10px;"
        >Only Show Empty</el-checkbox
      >
      <el-select
        size="small"
        v-model="downloadLang"
        placeholder="Choose language to upload"
        style="float:left; width: 120px; margin-left: 10px;"
      >
        <el-option
          v-for="item in langs"
          :key="item.key + Math.random()"
          :label="item.value"
          :value="item.key"
        >
        </el-option>
      </el-select>
      <el-button
        size="small"
        icon="el-icon-download"
        type="primary"
        @click="saveToFile('en')"
        :disabled="props.length == 0"
        style="margin-left:10px;"
        >Download {{ dic[downloadLang] }}
      </el-button>
      <el-checkbox
        border
        v-if="true"
        v-model="onlyShowDiffer"
        style="margin-left: 10px; float:left;"
        type="primary"
        size="small"
        @change="handleCompare"
        >Show Differ</el-checkbox
      >
      <!-- <el-button
        size="mini"
        icon="el-icon-download"
        type="primary"
        round
        @click="saveToFile('en')"
        :disabled="props.length == 0"
        style="margin-left:10px;"
        >English</el-button
      >
      <el-button
        size="mini"
        :disabled="props.length == 0"
        round
        icon="el-icon-download"
        type="primary"
        @click="saveToFile('zh')"
        style="margin-left:10px;"
        >Chinese</el-button
      >
      <el-button
        size="mini"
        round
        :disabled="props.length == 0"
        icon="el-icon-download"
        type="primary"
        style="margin-left:10px;"
        @click="saveToFile('de')"
        >German</el-button
      > -->
      <el-tag type="success" style="margin-left: 20px;"
        >In total: {{ selectedProps.length }}</el-tag
      >
    </div>
    <el-table
      v-loading="loading"
      size="mini"
      :data="selectedProps"
      border
      strip
      fit
      :height="tableHeight"
      style="margin-top: 10px; float:left;"
      ref="tableProperty"
      :cell-style="
        ({ row, column, rowIndex, columnIndex }) => {
          return cellStyle(row, columnIndex);
        }
      "
    >
      <el-table-column prop="key" label="Key" sortable></el-table-column>
      <el-table-column
        v-for="item in selectedLangs"
        :key="item + Math.random()"
        :prop="item"
        :label="dic[item]"
      ></el-table-column>
      <!-- <el-table-column prop="en" label="English" sortable></el-table-column>
      <el-table-column prop="zh" label="Chinese" sortable> -->
      <!-- <template slot-scope="scope">
          <el-input
            size="mini"
            v-model="scope.row.zh"
            type="textarea"
            clearable
          ></el-input>
        </template> -->
      <!-- </el-table-column> -->
      <!-- <el-table-column prop="de" label="German" sortable> -->
      <!-- <template slot-scope="scope">
          <el-input
            size="mini"
            v-model="scope.row.de"
            type="textarea"
            clearable
          ></el-input>
        </template> -->
      <!-- </el-table-column> -->
      <el-table-column width="80px" label="Edit" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            plain
            @click="handleEdit(scope)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" :visible.sync="dialogVisible">
      <dic-detail
        v-if="dialogVisible"
        @confirm="handleConfirm"
        @cancel="handleCancel"
        :editProperty="property"
      ></dic-detail>
    </el-dialog>
  </div>
</template>
<script>
import http from "../utils/http";
import config from "../utils/config";
import DicDetail from "./DicDetail.vue";
export default {
  name: "Translate",
  components: {
    DicDetail,
  },
  data() {
    return {
      onlyShowEmpty: false,
      properties: [],
      props: [],
      kw: "",
      loading: false,
      tableHeight: window.innerHeight - 170,
      dialogVisible: false,
      title: "Create Property",
      property: {},
      langs: config.langs,
      selectedLangs: ["en", "zh", "de"],
      downloadLang: "en",
      dic: {},
      onlyShowDiffer: false,
    };
  },
  created() {
    this.getProperties();
    this.langs.forEach((lan) => {
      this.dic[lan.key] = lan.value;
    });
    console.log(this.dic);
    this.selectedLangs = localStorage.getItem("selectedLangs");
    this.$nextTick(() => {
      this.$refs.tableProperty.doLayout();
    });
  },
  mounted() {
    if (
      localStorage.getItem("selectedLangs") != null &&
      localStorage.getItem("selectedLangs") != ""
    ) {
      this.selectedLangs = localStorage.getItem("selectedLangs").split(",");
    }
    this.$refs.tableProperty.doLayout();
  },
  computed: {
    selectedProps() {
      var round1 = this.props.filter((value) => {
        return value.flag == 1;
      });
      var round2 = round1.filter((value) => {
        var kw = this.kw.toLowerCase().trim();
        // improve
        var flag = false;
        this.selectedLangs.forEach((lang) => {
          value[lang] = value[lang] == null ? "" : value[lang];
          if (value[lang].toLowerCase().indexOf(kw) > -1) {
            flag = true;
            return;
          }
        });
        return flag;
        // value.key.toLowerCase().indexOf(kw) > -1 ||
        // value.en.toLowerCase().indexOf(kw) > -1 ||
        // value.zh.toLowerCase().indexOf(kw) > -1 ||
        // value.de.toLowerCase().indexOf(kw) > -1
      });
      var round3 = round2.filter((value) => {
        if (this.onlyShowEmpty) {
          var flag = false;
          this.selectedLangs.forEach((lang) => {
            value[lang] = value[lang] == null ? "" : value[lang].trim();
            if (value[lang] == "") {
              flag = true;
              return;
            }
          });
          return flag;
          // value.en.trim() == "" ||
          // value.zh.trim() == "" ||
          // value.de.trim() == ""
        } else {
          return true;
        }
      });
      return round3;
    },
  },
  methods: {
    cellStyle(row, columnIndex) {
      if (
        columnIndex > 0 &&
        this.onlyShowDiffer == true &&
        row[this.selectedLangs[0]] != row[this.selectedLangs[1]]
      )
        return "color: red";
    },
    handleCompare() {
      if (this.selectedLangs.length != 2) {
        this.$message({
          type: "error",
          message:
            "You can only compare two, please select only two to compare!",
        });
        this.onlyShowDiffer = false;
        return;
      }
    },
    handleLangChange(selectedLangs) {
      if (selectedLangs.length != 2) {
        this.onlyShowDiffer = false;
      }
      localStorage.setItem("selectedLangs", selectedLangs);
      this.$nextTick(() => {
        this.$refs.tableProperty.doLayout();
      });
    },
    // export txt
    saveToFile() {
      // var title = '# ' + language
      var str = "";
      // improve
      this.props.forEach((item) => {
        if (item.flag == 0) {
          str += item.marker + "\n";
          // str += item.marker + "\r\n";
        } else {
          str += item.key + " = " + item[this.downloadLang] + "\r\n";
        }
      });
      // improve end
      // if (lang == "en") {
      //   this.props.forEach((item) => {
      //     if (item.flag == 0) {
      //       str += item.marker + "\n";
      //       // str += item.marker + "\r\n";
      //     } else {
      //       str += item.key + " = " + item.en + "\r\n";
      //     }
      //   });
      // } else if (lang == "zh") {
      //   this.props.forEach((item) => {
      //     if (item.flag == 0) {
      //       str += item.marker + "\n";
      //       // str += item.marker + "\r\n";
      //     } else {
      //       str += item.key + " = " + item.zh + "\r\n";
      //     }
      //   });
      // } else if (lang == "de") {
      //   this.props.forEach((item) => {
      //     if (item.flag == 0) {
      //       str += item.marker + "\n";
      //       // str += item.marker + "\r\n";
      //     } else {
      //       str += item.key + " = " + item.de + "\r\n";
      //     }
      //   });
      // }
      str = str.slice(0, str.length - 2);
      var export_blob = new Blob([str]);
      var save_link = document.createElement("a");
      save_link.href = window.URL.createObjectURL(export_blob);
      // save_link.download = '导出'+'.txt';
      save_link.download = this.downloadLang + ".properties";
      // if (lang == "en") {
      //   save_link.download = "en.properties";
      // } else if (lang == "zh") {
      //   save_link.download = "zh.properties";
      // } else if (lang == "de") {
      //   save_link.download = "de.properties";
      // }
      this.fakeClick(save_link);
    },
    fakeClick(obj) {
      var ev = document.createEvent("MouseEvents");
      ev.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      obj.dispatchEvent(ev);
    },

    handleDelete(scope) {
      http.post("/property/deleteById", { _id: scope.row._id }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: "The property has been deleted successfully!",
          });
          this.properties.splice(this.properties.indexOf(scope.row), 1);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleCreate() {
      this.property = {};
      this.dialogVisible = true;
    },
    handleConfirm(property) {
      var _this = this;
      this.dialogVisible = false;
      this.props.forEach((value) => {
        if (value.key == property.key) {
          _this.langs.forEach((lang) => {
            value[lang.key] = property[lang.key];
          });
          // value.en = property.en;
          // value.zh = property.zh;
          // value.de = property.de;
        }
      });
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    handleEdit(scope) {
      this.title = "Edit Property";
      this.property = scope.row;
      this.dialogVisible = true;
    },
    getProperties() {
      http.get("/property").then((res) => {
        if (res.data.status == 1) {
          this.properties.splice(0, this.properties.length);
          this.properties = res.data.data;
        } else {
          this.$$message.error(res.data.message);
        }
      });
    },
    handleOnChange(file) {
      this.getProperties();
      if (file.status !== "ready") return;
      var suffix = file.name.split(".");
      if (suffix[suffix.length - 1] != "properties") {
        this.$message.error("Only .properties is allowed to be uploaded.");
        return;
      }
      var _this = this;
      var array = [];
      var newRecords = 0;
      var reader = new FileReader();
      reader.onload = function() {
        if (reader.result) {
          //打印文件内容
          // console.log(reader.result);
          _this.loading = true;
          var lines = reader.result.split("\n");

          lines.forEach((value) => {
            var items = value.split("=");
            if (items.length == 2 && items[0].slice(0, 1) != "#") {
              var key = items[0].trim();
              var index = _this.properties.findIndex((v) => {
                return v.key.trim() == key;
              });
              if (index > -1) {
                var obj = {};
                obj.flag = 1;
                obj.key = key;
                _this.langs.forEach((lang) => {
                  obj[lang.key] = _this.properties[index][lang.key];
                });

                array.push(obj);
                // array.push({
                //   flag: 1,
                //   key: key,
                //   en:
                //     _this.properties[index].en == null
                //       ? ""
                //       : _this.properties[index].en,
                //   zh:
                //     _this.properties[index].zh == null
                //       ? ""
                //       : _this.properties[index].zh,
                //   de:
                //     _this.properties[index].de == null
                //       ? ""
                //       : _this.properties[index].de,
                // });
              } else {
                array.push({
                  key: key,
                  en: items[1].trim(),
                  // zh: "",
                  // de: "",
                  flag: 1,
                });
                http.post("/property/insertOne", {
                  key: key,
                  en: items[1] == null ? "" : items[1].trim(),
                });
                newRecords += 1;
              }
            } else {
              array.push({
                marker: value,
                flag: 0,
              });
            }
          });
          _this.props.splice(0, _this.props.length);
          _this.props = array;
          _this.loading = false;
          _this.$message({
            type: "success",
            message: newRecords + " new records were added in dictionary",
          });
        }
      };
      // console.log("start to read");
      reader.readAsText(file.raw, "utf-8");
      // reader.readAsText(file.raw)
    },
  },
};
</script>
<style scoped>
.header {
  min-width: 1100px;
  height: 50px;
  line-height: 50px;
  text-align: left;
  vertical-align: middle;
  padding: 5px 0;
  /* overflow: scroll; */
  /* margin-top: -10px; */
  display: flex;
  align-items: center;
}
</style>
